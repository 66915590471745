<template>
  <div
    class="before:animate-heart-beat before:bg-green relative isolate flex aspect-square min-w-[152px] max-w-[380px]
    items-center justify-center before:absolute before:w-full before:rounded-full before:pt-[100%]"
  >
    <div class="relative size-fit max-h-[calc(100%_/_1.41421356237)] max-w-[calc(100%_/_1.41421356237)]">
      <slot />
    </div>
  </div>
</template>
